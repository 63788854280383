import React from 'react';

export const PauseIcon: React.FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 23">
    <g fill="none" fillRule="evenodd">
      <rect
        fillOpacity={0.01}
        fill="#FFF"
        fillRule="nonzero"
        width={24}
        height={24}
        rx={1}
      />
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3.2 3.2)"
        stroke="#3C4A55"
        strokeWidth={1.6}
      >
        <path d="M6.4 12V5.6M11.2 12V5.6" />
        <circle cx={8.8} cy={8.8} r={8.8} />
      </g>
    </g>
  </svg>
);


