import * as React from "react";

export const TrashIcon: React.FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect fill="#FFF" fillRule="nonzero" width={24} height={24} rx={1} />
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#FF3232"
        strokeWidth={1.6}
      >
        <path d="M8.8 6.4V3.2h6.4v3.2M18.4 12v7.2a1.6 1.6 0 0 1-1.6 1.6H7.2a1.6 1.6 0 0 1-1.6-1.6V12M12 13.6v3.2M8.8 13.6v3.2M15.2 13.6v3.2M3.2 6.4h17.6v3.2H3.2z" />
      </g>
    </g>
  </svg>
);
