import React from 'react';
import { TrashIcon } from '../../icons';
import { HeaderWrapper, Input, Subtitle, SubValue, TimesWrapper, Wrapper } from './styled';

type Time = {
  ms: number;
  s: number;
  m: number;
  h: number;
};

interface IProps {
  id: string;
  index: number;
  time: Time;
  lap: Time;
  value: string;

  onDelete(index: number): void;

  onCommentChange(id: string, text: string): void;
}

const RenderItem: React.FC<IProps> = ({ id, index, time, lap, onDelete, onCommentChange, value }) => {
  const onRemove = () => onDelete(index);
  const onTextChange = (e: any) => onCommentChange(id, e.target.value);
  return (
    <div className="col-md-6">
      <Wrapper>
        <HeaderWrapper>
          <TimesWrapper>
            <TimesWrapper mr={24}>
              <Subtitle>Timer:</Subtitle>
              <SubValue>{`${time.h < 10 ? `0${time.h}` : time.h}:${time.m < 10 ? `0${time.m}` : time.m}:${time.s < 10 ? `0${time.s}` : time.s}.${time.ms < 10 ? `0${time.ms}` : time.ms}`}</SubValue>
            </TimesWrapper>
            <TimesWrapper>
              <Subtitle>Lap:</Subtitle>
              <SubValue>{`${lap.h < 10 ? `0${lap.h}` : lap.h}:${lap.m < 10 ? `0${lap.m}` : lap.m}:${lap.s < 10 ? `0${lap.s}` : lap.s}.${lap.ms < 10 ? `0${lap.ms}` : lap.ms}`}</SubValue>
            </TimesWrapper>
          </TimesWrapper>
          <button onClick={onRemove} style={{ marginRight: 0 }}>
            <TrashIcon />
          </button>
        </HeaderWrapper>
        <Input
          type="text"
          placeholder="Write something"
          onChange={onTextChange}
          value={value}
          autoFocus={index === 0}
        />
      </Wrapper>
    </div>
  );
};

export default RenderItem;
