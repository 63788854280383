import * as React from 'react';

export const StartIcon: React.FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect
        fillOpacity={0.01}
        fill="#FFF"
        fillRule="nonzero"
        width={24}
        height={24}
        rx={1}
      />
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3.2 3.2)"
        strokeWidth={1.6}
      >
        <circle stroke="#3C4A55" cx={8.8} cy={8.8} r={8.8} />
        <path d="M12.375 5.6a4.8 4.8 0 1 1-7.153 0" stroke="#3C4A55" />
        <path d="M8.8 3.2V8" stroke="#425165" />
      </g>
    </g>
  </svg>
);
