import * as React from 'react';

export const ResetIcon: React.FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect
        fillOpacity={0.01}
        fill="#FFF"
        fillRule="nonzero"
        width={24}
        height={24}
        rx={1}
      />
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#3C4A55"
        strokeWidth={1.6}
      >
        <path d="M20 12c0-4.4-3.6-8-8-8-3.12 0-5.84 1.76-7.12 4.4" />
        <path
          d="M4.24 3.68l.64 4.72 4.8-.64M17.808 17.508A7.73 7.73 0 0 0 19.12 15.6M12.2 19.998a8.035 8.035 0 0 0 2.56-.481M6.293 17.592a8.072 8.072 0 0 0 2.156 1.571M4 12c0 .834.13 1.638.369 2.395" />
      </g>
    </g>
  </svg>
);
