import React from 'react';

export const SortIcon: React.FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect
        fillOpacity={0.01}
        fill="#FFF"
        fillRule="nonzero"
        width={24}
        height={24}
        rx={1}
      />
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#3C4A55"
        strokeWidth={1.6}
      >
        <path d="M8 8.8l4-4 4 4M16 15.2l-4 4-4-4" />
      </g>
    </g>
  </svg>
);
