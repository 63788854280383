import * as React from 'react';
import { v4 } from 'uuid';
import RenderItem from './component/RenderList/RenderItem';
import { CopyIcon, LapIcon, PauseIcon, ResetIcon, SortIcon, StartIcon } from './icons';
import logo from './Logo.svg';

interface IState {
  sortByBigger: boolean;
  ms: number;
  s: number;
  m: number;
  h: number;
  lapCounter: {
    ms: number;
    s: number;
    m: number;
    h: number;
  };
  laps: Array<{
    id: string;
    time: {
      ms: number;
      s: number;
      m: number;
      h: number;
    },
    lapTime: {
      ms: number;
      s: number;
      m: number;
      h: number;
    };
    comment: string;
  }>;
}

class App extends React.Component<{}, IState> {
  public timer: any = null;
  public clipboardContainer: any = null;

  constructor(props: {}) {
    super(props);
    this.state = {
      sortByBigger: true,
      ms:           0,
      s:            0,
      m:            0,
      h:            0,
      lapCounter:   {
        ms: 0,
        s:  0,
        m:  0,
        h:  0,
      },
      laps:         [],
    };
  }

  public stopWatch = (ms: number, s: number, m: number, h: number) => {
    ms++;
    if (ms === 100) {
      ms = 0;
      s++;
    }
    if (s === 60) {
      s = 0;
      m++;
    }
    if (m === 60) {
      m = 0;
      h++;
    }
    return { ms, s, m, h };
  };

  public run = (): void => {
    const { ms, s, m, h, lapCounter } = this.state;
    this.setState({ lapCounter: this.stopWatch(lapCounter.ms, lapCounter.s, lapCounter.m, lapCounter.h) });
    this.setState(this.stopWatch(ms, s, m, h));
  };

  public start = () => {
    if (!this.timer) {
      this.timer = setInterval(this.run, 1);
    }
  };
  public reset = () => {
    const startPoint = { ms: 0, s: 0, m: 0, h: 0 };
    this.stopTimer();
    this.setState(startPoint);
    this.setState({ lapCounter: startPoint });
  };
  public pause = () => {
    this.stopTimer();
  };
  public lap = () => {
    if (this.timer) {
      const startPoint = { ms: 0, s: 0, m: 0, h: 0 };
      this.setState(state => ({
        laps: [...state.laps,
          {
            id:      v4(),
            time:    {
              ms: state.ms,
              s:  state.s,
              m:  state.m,
              h:  state.h,
            },
            lapTime: {
              ms: state.lapCounter.ms,
              s:  state.lapCounter.s,
              m:  state.lapCounter.m,
              h:  state.lapCounter.h,
            },
            comment: '',

          },
        ],
      }), () => {
        this.setState({ lapCounter: startPoint });
      });
    }
  };

  public stopTimer = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  public onRemoveItem = (index: number): void => {
    const laps = this.state.laps;
    laps.splice(index, 1);
    this.setState({ laps });
  };

  public onCommentChange = (id: string, text: string): void => {
    const index = this.state.laps.map(item => item.id).indexOf(id);
    if (index > -1) {
      const laps = this.state.laps;
      laps[index].comment = text;
      this.setState({ laps });
    }
  };

  public onSort = () => {
    this.setState({ laps: this.state.laps.reverse() });
  };

  public onCopy = () => {
    if (this.state.laps.length > 0) {
      this.clipboardContainer.select();
      document.execCommand('copy');
      alert('Copied');
    } else {
      alert('no laps');
    }
  };

  public render(): JSX.Element {
    const { ms, s, m, h, laps } = this.state;
    const value = laps.map(
      item => `${item.lapTime.h < 10 ? `0${item.lapTime.h}` : item.lapTime.h}:${item.lapTime.m < 10 ? `0${item.lapTime.m}` : item.lapTime.m}:${item.lapTime.s < 10 ? `0${item.lapTime.s}` : item.lapTime.s}.${item.lapTime.ms < 10 ? `0${item.lapTime.ms}` : item.lapTime.ms}, ${item.time.h < 10 ? `0${item.time.h}` : item.time.h}:${item.time.m < 10 ? `0${item.time.m}` : item.time.m}:${item.time.s < 10 ? `0${item.time.s}` : item.time.s}.${item.time.ms < 10 ? `0${item.time.ms}` : item.time.ms}:${item.time.ms}, ${item.comment}\n`);
    const list = [...laps].reverse();
    return (
      <div className="container-fluid" style={{ textAlign: 'center' }}>
        <div className="row">
          <div className="col-sm-12">
            <img className="logo" src={logo} alt="logo" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h1 className="res-time">{`${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}.${ms < 10 ? `0${ms}` : ms}`}</h1>
          </div>
        </div>
        <div className="row d-flex justify-content-center" style={{ marginBottom: 24 }}>
          <button onClick={this.onSort}><SortIcon />Sort</button>
          <button onClick={this.start}><StartIcon />Start</button>
          <button onClick={this.pause}><PauseIcon />Pause</button>
          <button onClick={this.reset}><ResetIcon />reset</button>
          <button onClick={this.lap}><LapIcon />Lap</button>
          <button onClick={this.onCopy}><CopyIcon />Copy</button>
        </div>
        <div style={{ overflow: 'scroll', height: 700 }}>
          {list.map((item, index) => {
            return (
              <div key={`index-${item.id}`} className="row d-flex justify-content-center">
                <RenderItem
                  id={item.id}
                  index={index}
                  time={item.time}
                  lap={item.lapTime}
                  value={item.comment}
                  onDelete={this.onRemoveItem}
                  onCommentChange={this.onCommentChange}
                />
              </div>
            );
          })}
        </div>
        <div className="row" style={{ opacity: 0 }}>
          <textarea ref={ref => this.clipboardContainer = ref} value={value.join('')} onChange={() => null} />
        </div>
      </div>
    );
  }
}

export default App;
