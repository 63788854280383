import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 156px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 6px;
  text-align: left;
  padding: 24px;
`;

export const HeaderWrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TimesWrapper = styled.div<{ mr?: number }>`
  display:flex;
  flex-direction: row;
  margin-right: ${({ mr }) => mr ? `${mr}px` : 0};
`;
export const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #242c33;
`;

export const SubValue = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #3c4a55;
  margin-left: 6px;
`;

export const Input = styled.input`
  height: 60px;
  width: 100%;
  background-color: rgba(224, 224, 224, 0.04);
  border-radius: 2px;
  border: 1px solid rgb(224, 224, 224);
  margin-top: 12px;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
  color: #242c33;
  line-height: 1.43;
  font-size: 14px;
  font-weight: 400;
  :focus {
    border: 1px solid #0A99D5;
  }
  ::placeholder {
    color: #999999;
    line-height: 1.43;
    font-size: 14px;
    font-weight: 400;
  }
`;

