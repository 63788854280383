import * as React from "react";

export const LapIcon: React.FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect
        fillOpacity={0.01}
        fill="#FFF"
        fillRule="nonzero"
        width={24}
        height={24}
        rx={1}
      />
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(4.8 2.4)"
        stroke="#3C4A55"
        strokeWidth={1.6}
      >
        <path d="M7.2 8v3.2h3.2" />
        <circle cx={7.2} cy={11.2} r={7.2} />
        <path d="M4.8.8h4.8M7.2.8v.8" />
      </g>
    </g>
  </svg>
);
